<template>
    <div>
        <v-card>
            <v-card-title>Asignación de Articulos a Centros de Costos</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="centrocosto"
                            :items="centros_costos"
                            item-text="nombre"
                            item-value="id"
                            item-key="id"
                            label="Centro de Costo"
                            return-object
                            @change="loadAsignacion"
                        >
                        </v-select>
                    </v-col>
                    <v-col>
                        <v-btn @click="saveAsignacion">Guardar</v-btn>
                    </v-col>
                    <v-col>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <!-- tabla de datos -->
        <v-data-table 
            :headers="articulosListHeaders" 
            :items="articulosListBody" 
            titulo="Listado de Articulos" 
            label="Escriba para buscar Articulos" 
            class="elevation-1"
            :hide-default-footer="true"
            :disable-pagination="true"
        >
            <template v-slot:header.id="">
                <input type="checkbox" @click="toggleSelect" :checked="selectAll"/>
            </template>
            <template v-slot:item.id="{ item }">
                <input type="checkbox" v-model="articulos" :value="item.id"/>
            </template>
        </v-data-table>
        <!-- notificacion -->
        <v-snackbar
            v-model="snackbar"
            :timeout="5000"
            :color="color"
        >
            {{ validation_message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<style scoped>

</style>

<script>
import {mapState, mapMutations} from 'vuex'

export default {
    data: () => ({
        color: 'green',
        snackbar: false,
        errors: [],
        validation_message: '',
        search: null,
        //articulos: [],
        centros_costos: [],
        centrocosto: {},
        articulosListHeaders: [
            {
                text: '?',
                value: 'id',
                sortable: false,
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Agrupación de cuentas',
                align: 'left',
                sortable: true,
                value: 'cuenta.nombre',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Subcosto',
                align: 'left',
                sortable: true,
                value: 'subcosto.nombre',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Descripción de Artículo',
                align: 'left',
                sortable: true,
                value: 'nombre',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Codigo',
                align: 'left',
                sortable: true,
                value: 'codigo',
                class: "blue-grey darken-4 white-text"
            },
        ],
        articulosListBody: [],
        articulos: []
    }),
    mounted() {
        this.loadCentroCostos();
        this.loadArticulos();
    },
    methods:{
        toggleSelect(){
            var select = this.selectAll;
            this.articulosListBody.forEach(function(articulo) {
                articulo.checked = !select;
            });
            this.selectAll = !select;
        },
        async loadArticulos(){
            let url = `${this.base_url}articulos`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.articulosListBody = response.data;
            }).catch((error)=>{
                console.log(error);
            });
        },
        async loadAsignacion(){
            let url = `${this.base_url}articulos/asignaciones/${this.centrocosto.id}`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.articulos = response.data;
            }).catch((error)=>{
                console.log(error);
            });
        },
        async loadCentroCostos(){
            let url = `${this.base_url}centros_costos`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.centros_costos = response.data;
                this.centrocosto = this.centros_costos[0];
                this.loadAsignacion();
            }).catch((error)=>{
                console.log(error);
            });
        },
        async saveAsignacion(){
            let asignaciones = {
                articulos: this.articulos.map(a => {
                    return {
                        articulos_id: a,
                        centrocostos_id: this.centrocosto.id,
                        activo: 1
                    }
                })
            };
            let url = `${this.base_url}articulos/asignar`;
            await this.axios.post(url, asignaciones, this.headers).then((response)=>{
                console.log(response)
                this.showSnackBar(false);
            }).catch((error)=>{
                this.errors = error.response.data;
                this.validation_message = this.errors.join();
                this.showSnackBar(true);
            });
        },
        showSnackBar(error){
            if(error){
                this.color = 'red';
            }else{
                this.color = 'green';
                this.validation_message = "Ejecutado exitosamente!";
            }
            this.snackbar = true;
        },
        ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
        ...mapState(['loading', 'base_url', 'headers']),
        selectAll: {
            get() {
                if (this.articulosListBody && this.articulosListBody.length > 0) { 
                let allChecked = true;

                for (const articulo of this.articulosListBody) {
                    if (!this.articulos.includes(articulo.id)) {
                        allChecked = false; // If even one is not included in array
                    }
                    // Break out of loop if mismatch already found
                    if(!allChecked) break;
                }
                    return allChecked;
                }
                return false;
            },
            set(value) {
                const checked = [];
                if (value) {
                    this.articulosListBody.forEach((articulo) => {
                        checked.push(articulo.id);
                    });
                }
                this.articulos = checked;
            }
        }
    },
    components:{
    }
  }
</script>